<template>
    <v-container style="max-height: 100%; height:100%" fluid class="px-6">
        <v-row class="ma-0 pa-0" style="height: 60px;" align="center" justify="center">
            <v-col cols="auto">
                <v-chip :disabled="available_campaigns_loading" class="section_breakdown_chip" :outlined="reporting_page !== 'portfolio'" label color="black" @click="reporting_page = 'portfolio';">Portfolio</v-chip>
                <v-chip :disabled="available_campaigns_loading" class="section_breakdown_chip" :outlined="reporting_page !== 'monitoring'" label color="black" @click="reporting_page = 'monitoring';">Monitoring</v-chip>
            </v-col>
            <v-spacer></v-spacer>
            <!-- Portfolio Controls -->
            <v-col class="py-0 px-1" cols="auto" style="display: flex; align-items: center; justify-content: center" align="center" justify="center" v-if="reporting_page === 'portfolio'">
                <v-row class="ma-0 pa-0" align="center" justify="center">
                    <v-col cols="auto" class="ma-0 pa-0 pr-3" style="min-width: 250px;">
                        <DateRange
                            :start_date="campaign_filtering_configuration['start_date']"
                            :end_date="campaign_filtering_configuration['end_date']"
                            :min_start_date="campaign_filtering_configuration['min_start_date']"
                            :max_end_date="campaign_filtering_configuration['max_end_date']"
                            v-on:date_update="campaign_filtering_date_updated"></DateRange>
                    </v-col>
                    <v-col cols="auto" class="ma-0 pa-0 pr-6">
                        <v-btn :disabled="available_campaigns_loading" class="filter-control" outlined @click="filter_campaigns">
                            <v-icon>mdi-magnify</v-icon>
                            Search
                        </v-btn>
                    </v-col>
                    <v-col class="ma-0 pa-0" cols="auto">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-menu
                                    v-model="menu"
                                    offset-y
                                    :close-on-content-click="false"
                                    width="348px"
                                    >
                                    <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
                                        <v-btn v-on="on" icon v-bind="menuAttrs" @click="menu = !menu" disabled>
                                            <v-icon color="black">mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card class="ma-0 pa-3" style="width: 348px;">
                                        <v-row class="ma-0 pa-0">
                                            <v-col class="ma-0 pa-0 pb-3" cols="auto">
                                                <span class="filter-title">Portfolio Settings</span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="ma-0 pa-0">
                                            <v-col class="ma-0 pa-0 py-1" cols="8">
                                                <v-text-field type="number" max="6" min="3" width="100%"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row class="ma-0 pa-0">
                                            <v-col class="ma-0 pa-0" cols="auto">
                                                <span>Statistics</span>
                                            </v-col>
                                            <v-col class="ma-0 pa-0" cols="12">
                                                <v-select label="Statistic 1"></v-select>
                                            </v-col>
                                            <v-col class="ma-0 pa-0" cols="12">
                                                <v-select label="Statistic 2"></v-select>
                                            </v-col>
                                            <v-col class="ma-0 pa-0" cols="12">
                                                <v-select label="Statistic 3"></v-select>
                                            </v-col>
                                            <v-col class="ma-0 pa-0" cols="12">
                                                <v-select label="Statistic 4"></v-select>
                                            </v-col>
                                            <v-col class="ma-0 pa-0" cols="12">
                                                <v-select label="Statistic 5"></v-select>
                                            </v-col>
                                            <v-col class="ma-0 pa-0" cols="12">
                                                <v-select label="Statistic 6"></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-menu>
                            </template>
                            <TooltipDescription title="Portfolio Settings"></TooltipDescription>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="px-3 py-0" cols="auto" style="display: flex; align-items: center; justify-content: center" align="center" justify="center" v-if="reporting_page === 'monitoring'">
                <v-row class="ma-0 pa-0" align="center">
                    <v-col cols="auto" class="pr-1 py-0">
                        <v-btn icon :disabled="campaign_id_list.indexOf(selected_campaign_id) == 0 || campaign_id_list.length == 0 || campaign_loading" @click="shift_campaign_id(-1)">
                            <v-icon color="black">mdi-arrow-left-circle</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="auto" class="px-1" style="width: 175px; max-width: 175px;">
                        <v-select :disabled="campaign_id_list.length == 0 || campaign_loading" v-model="selected_campaign_id" :items="campaign_id_list" class="ma-0 pa-0" label="Campaign ID" hide-details @change="select_campaign"></v-select>
                    </v-col>
                    <v-col cols="auto" class="pl-1 py-0">
                        <v-btn icon :disabled="campaign_id_list.indexOf(selected_campaign_id) == (campaign_id_list.length - 1) || campaign_id_list.length == 0 || campaign_loading" @click="shift_campaign_id(1)">
                            <v-icon color="black">mdi-arrow-right-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <!-- Monitoring Controls -->
            <v-col class="px-1 pr-0" cols="auto" style="display: flex; align-items: center; justify-content: center" align="center" justify="center" v-if="reporting_page === 'monitoring'">
                <v-row class="ma-0 pa-0" align="center">
                    <v-col class="ma-0 pa-0" cols="auto">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-chip :disabled="campaign_loading || selected_campaign_id == null" v-on="on" class="data_breakdown_chip" :outlined="statistic_style !== 'cumulative'" label color="black" @click="statistic_style = 'cumulative';"><v-icon>mdi-chart-bell-curve-cumulative</v-icon></v-chip>
                            </template>
                            <TooltipDescription title="Cumulative Statistics"></TooltipDescription>
                        </v-tooltip>
                    </v-col>
                    <v-col class="ma-0 pa-0" cols="auto">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-chip :disabled="campaign_loading || selected_campaign_id == null" v-on="on" class="data_breakdown_chip" :outlined="statistic_style !== 'pacing'" label color="black" @click="statistic_style = 'pacing';"><v-icon>mdi-chart-bar</v-icon></v-chip>
                            </template>
                            <TooltipDescription title="Pacing Statistics"></TooltipDescription>
                        </v-tooltip>
                    </v-col>
                    <v-col class="ma-0 pa-0" cols="auto">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-menu
                                    v-model="menu"
                                    offset-y
                                    :close-on-content-click="false"
                                    width="348px"
                                    >
                                    <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
                                        <v-btn v-on="on" icon v-bind="menuAttrs" @click="menu = !menu" :disabled="campaign_loading || selected_campaign_id == null">
                                            <v-icon color="black">mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card class="ma-0 pa-3" style="width: 348px;">
                                        <v-row class="ma-0 pa-0">
                                            <v-col class="ma-0 pa-0 pb-3" cols="auto">
                                                <span class="filter-title">Monitoring Settings</span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="ma-0 pa-0">
                                            <v-col class="ma-0 pa-0 py-1" cols="auto">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-chip :disabled="available_campaign_info == null" v-on="on" class="data_breakdown_chip_menu" :outlined="statistic_timeframe !== 'daily'" label color="black" @click="statistic_timeframe = 'daily';"><v-icon>mdi-calendar-today</v-icon>Daily</v-chip>
                                                    </template>
                                                    <TooltipDescription title="Daily"></TooltipDescription>
                                                </v-tooltip>
                                            </v-col>
                                            <v-col class="ma-0 pa-0 py-1" cols="auto">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-chip :disabled="available_campaign_info == null || true" v-on="on" class="data_breakdown_chip_menu" :outlined="statistic_timeframe !== 'weekly'" label color="black" @click="statistic_timeframe = 'weekly';"><v-icon>mdi-calendar-week</v-icon>Weekly</v-chip>
                                                    </template>
                                                    <TooltipDescription title="Weekly"></TooltipDescription>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                        <v-row class="ma-0 pa-0">
                                            <v-col class="ma-0 pa-0 py-1" cols="auto">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-chip :disabled="available_campaign_info == null" v-on="on" class="data_breakdown_chip_menu" :outlined="statistic_type !== 'unique'" label color="black" @click="statistic_type = 'unique';"><v-icon>mdi-account</v-icon>Unique Reach</v-chip>
                                                    </template>
                                                    <TooltipDescription title="Unique Reach"></TooltipDescription>
                                                </v-tooltip>
                                            </v-col>
                                            <v-col class="ma-0 pa-0 py-1" cols="auto">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-chip :disabled="available_campaign_info == null" v-on="on" class="data_breakdown_chip_menu" :outlined="statistic_type !== 'device'" label color="black" @click="statistic_type = 'device';"><v-icon>mdi-devices</v-icon>Device Reach</v-chip>
                                                    </template>
                                                    <TooltipDescription title="Device Reach"></TooltipDescription>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-menu>
                            </template>
                            <TooltipDescription title="Monitoring Settings"></TooltipDescription>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="ma-0 pa-0" v-if="reporting_page=='monitoring'">
            <v-col cols="7" style="height: calc(100vh - 177px); width: 100%;">
                <ReportingReachCurve :data="selected_campaign_data" :stat="statistic_style" :type="statistic_type"></ReportingReachCurve>
            </v-col>
            <v-col cols="5" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="frequency_card">
                        <ReportingFrequencyDistribution :data="selected_campaign_data" :stat="statistic_style" :type="statistic_type"></ReportingFrequencyDistribution>
                    </v-col>
                    <v-col cols="12" class="frequency_card">
                        <ReportingHighlights :data="selected_campaign_highlights"></ReportingHighlights>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" v-if="reporting_page=='portfolio'">
            <v-col cols="2" style="height: 175px; width: 100%;">
                <ReportingMajorHighlight :data="campaign_portfolio" title="Time Range Impressions" data_key="time_range_impressions"></ReportingMajorHighlight>
            </v-col>
            <v-col cols="2" style="height: 175px; width: 100%;">
                <ReportingMajorHighlight :data="campaign_portfolio" title="Campaign Total Impressions" data_key="total_impresions"></ReportingMajorHighlight>
            </v-col>
            <v-col cols="2" style="height: 175px; width: 100%;">
                <ReportingMajorHighlight :data="campaign_portfolio" title="Total Campaigns" data_key="total_campaigns"></ReportingMajorHighlight>
            </v-col>
            <v-col cols="2" style="height: 175px; width: 100%;">
                <ReportingMajorHighlight :data="campaign_portfolio" title="Running Campaigns" data_key="running_campaigns"></ReportingMajorHighlight>
            </v-col>
            <v-col cols="2" style="height: 175px; width: 100%;">
                <ReportingMajorHighlight :data="campaign_portfolio" title="Completed Campaigns" data_key="finished_campaigns"></ReportingMajorHighlight>
            </v-col>
            <v-col cols="2" style="height: 175px; width: 100%;">
                <ReportingMajorHighlight :data="campaign_portfolio" title="New Campaigns" data_key="new_campaigns"></ReportingMajorHighlight>
            </v-col>
            <v-col cols="12" style="height: calc(100vh - 352px); width: 100%;">
                <ReportingPortfolio :data="campaign_portfolio" v-on:switch_campaign="switch_campaign"></ReportingPortfolio>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import api_caller from '../javascript/reporting_api_caller';

import DateRange from '../components/Reporting/DateRange.vue';
import TooltipDescription from '../components/Reporting/TooltipDescription.vue'

import ReportingPortfolio from '../components/Reporting/ReportingPortfolio.vue';
import ReportingMajorHighlight from '../components/Reporting/ReportingMajorHighlight.vue';

import ReportingHighlightsShort from '../components/Reporting/ReportingHighlightsShort.vue';
import ReportingHighlights from '../components/Reporting/ReportingHighlights.vue';
import ReportingReachCurve from '../components/Reporting/ReportingReachCurve.vue';
import ReportingFrequencyDistribution from '../components/Reporting/ReportingFrequencyDistribution.vue';
import ReportingFilterCard from '../components/Reporting/ReportingFilterCard.vue';

export default {
    name: 'Reporting',
    components: {
        DateRange,
        TooltipDescription,
        ReportingHighlightsShort,
        ReportingHighlights,
        ReportingReachCurve,
        ReportingFrequencyDistribution,
        ReportingFilterCard,

        ReportingPortfolio,
        ReportingMajorHighlight
    },
    props: [
        
    ],
    data(){
        return {
            reporting_page: 'portfolio',

            filter_page: 'campaign_filtering',
            filter_page_available: true,
            frequency_chart: false,
            statistic_style: 'cumulative',
            statistic_type: 'device',
            statistic_timeframe: 'daily',
            
            // Loading Status
            available_campaigns_loading: false,
            campaign_loading: false,

            // Campaign Filters
            campaign_filtering_configuration: null,
            campaign_filtering_configuration_base : null,
            // Avail
            available_campaign_info: -1,
            campaign_id_list: [],
            campaign_portfolio: -1,
            //Selected Campaign
            selected_campaign: null,
            selected_campaign_id: null,
            selected_campaign_highlights: -1,
            selected_campaign_data: -1

            ,menu: false
        }
    },
    mounted(){
        this.pull_available_configurations();
    },
    watch: {
        statistic_timeframe: {
          handler: function(){
              console.log('Here');
              let body = {
                'campaign_id': this.selected_campaign['campaign_id'],
                'time_grouping': this.statistic_timeframe
              }
              this.switch_campaign(body, this.selected_campaign);

          },
          immediate: false
        }
    },
    methods: { 
        pull_available_configurations: async function(){
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = yyyy + '-' + mm + '-' + dd;
            this.campaign_filtering_configuration = {
                'min_start_date': '2023-06-20',
                'max_end_date': today,
                'start_date': '2023-06-20',
                'end_date': today,
                'date_inclusivity': 'end_date',
                'filters_changed': true,
                'filter_page': 'campaign_filtering'
            }
            this.campaign_filtering_configuration_base = {...this.campaign_filtering_configuration};
            this.campaign_filtering_configuration_base['date_inclusivity'] = null;
        },
        campaign_filtering_date_updated: function(date){
            let body = {...this.campaign_filtering_configuration};
            body['start_date'] = date[0];
            body['end_date'] = date[1];

            this.updated_search_config(body)
        },
        updated_search_config: function(body){
            this.campaign_filtering_configuration['start_date'] = body['start_date'];
            this.campaign_filtering_configuration['end_date'] = body['end_date'];
            this.campaign_filtering_configuration['date_inclusivity'] = body['inclusivity'];
            this.campaign_filtering_configuration['filters_changed'] = body['filters_changed'];
            this.campaign_filtering_configuration['filter_page'] = body['filter_page'];
            if(this.campaign_filtering_configuration['start_date'] == this.campaign_filtering_configuration_base['start_date'] && this.campaign_filtering_configuration['end_date'] == this.campaign_filtering_configuration_base['end_date'] && this.campaign_filtering_configuration['date_inclusivity'] == this.campaign_filtering_configuration_base['date_inclusivity']){
                this.campaign_filtering_configuration['filters_changed'] = false;
            }
        },
        filter_campaigns: function(){
            let filter = {'start_date': new Date(this.campaign_filtering_configuration['start_date']).toISOString().slice(0, 10).split('-').reverse().join('-'),
                        'end_date': new Date(this.campaign_filtering_configuration['end_date']).toISOString().slice(0, 10).split('-').reverse().join('-'),
                        'inclusivity': this.campaign_filtering_configuration['date_inclusivity']}
            this.pull_available_campaigns(filter)
        },
        pull_available_campaigns: async function(body){
            this.available_campaigns_loading = true;
            this.available_campaign_info = null;
            this.selected_campaign_data = -1;
            this.selected_campaign_highlights = -1;
            this.pull_campaign_portfolio(body)
            let token = await this.$auth.getTokenSilently();
            let available_campaign_data = await api_caller.available_configurations(token, body).then((resp) => {
                if (resp["success"]) {
                    return resp['data'];
                }
                    return false;
                })
                .catch((e) => {
                    console.log("error", e);
                    return false;
                });
            if(available_campaign_data){
                this.available_campaigns = available_campaign_data['campaign_ids'];
                this.available_campaign_info = available_campaign_data['campaigns'];
                this.campaign_id_list = Object.keys(this.available_campaign_info);
                this.campaign_filtering_configuration['filters_changed'] = false;
                this.campaign_filtering_configuration['filter_page'] = 'campaign_selection';
                this.campaign_filtering_configuration_base = {...this.campaign_filtering_configuration};
                this.selected_campaign = {
                    'campaign_id': null,
                    'start_date': null,
                    'end_date': null
                }
            }
            else{
                this.available_campaign_info = -1;
                this.campaign_id_list = [];
            }
            this.available_campaigns_loading = false;
        },
        pull_campaign_portfolio: async function(body) {
            this.campaign_portfolio = null;
            let token = await this.$auth.getTokenSilently();
            let available_campaign_data = await api_caller.campaign_info(token, body).then((resp) => {
                if (resp["success"]) {
                    return resp['data'];
                }
                    return false;
                })
                .catch((e) => {
                    console.log("error", e);
                    return false;
                });
            if(available_campaign_data){
                this.campaign_portfolio = available_campaign_data;
            }
            else{
                this.campaign_portfolio = -1;
            }
        },
        shift_campaign_id: function(shift_by){
            let cur_idx = this.campaign_id_list.indexOf(this.selected_campaign_id);
            cur_idx += shift_by;
            if(cur_idx < 0 || cur_idx == this.campaign_id_list.length){
                return;
            }
            else{
                this.selected_campaign_id = this.campaign_id_list[cur_idx];
                this.select_campaign();
            }
        },
        select_campaign: function(){
            let campaign_info = this.available_campaign_info[this.selected_campaign_id];
            this.selected_campaign_min_start_date = campaign_info['campaign_start_date'];
            this.selected_campaign_max_end_date = campaign_info['campaign_end_date'];
            this.selected_campaign_start_date = campaign_info['campaign_start_date'];
            this.selected_campaign_end_date = campaign_info['campaign_end_date'];

            let body = {
                'campaign_id': this.selected_campaign_id,
                'time_grouping': 'daily'
            };
            let campaign = {
                'campaign_id': this.selected_campaign_id,
                'start_date': this.selected_campaign_start_date,
                'end_date': this.selected_campaign_end_date
            }
            this.switch_campaign(body,campaign);
        },
        switch_campaign: async function(body, campaign){
            this.campaign_loading = true;
            this.reporting_page = 'monitoring';
            body['time_grouping'] = this.statistic_timeframe;
            this.selected_campaign_id = campaign['campaign_id'];
            this.selected_campaign_highlights = null;
            this.selected_campaign_data = null;
            this.selected_campaign = campaign;
            let token = await this.$auth.getTokenSilently();
            let reporting_data = await api_caller.configuration_data(token, body).then((resp) => {
                            if (resp["success"]) {
                                return resp['data'];
                            }
                            console.log(resp)
                                return false;
                            })
                            .catch((e) => {
                                console.log("error", e);
                                return false;
                            });
            if(reporting_data){
                this.selected_campaign_highlights = reporting_data['highlights'];
                this.selected_campaign_data = reporting_data['campaign_data'];
            }
            else{
                this.selected_campaign_highlights = -1;
                this.selected_campaign_data = -1;
            }
            this.campaign_loading = false;
        }
    }
}
</script>

<style scoped>
    .frequency_card{
        height: calc((100vh - 177px) * 0.5);
        overflow-y: scroll;
    }
    .highlights_card_short{
        height: 100px;
        overflow-y: scroll;
    }

    .section_breakdown_chip {
        font-family: Poppins;
        color: white !important;
        font-weight: 600;
        width: 150px;
        justify-content: center;
        margin-left: 1px;
        margin-right: 1px;
    }
    .data_breakdown_chip {
        font-family: Poppins;
        color: white !important;
        font-weight: 600;
        width: 60px;
        justify-content: center;
        margin-left: 1px;
        margin-right: 1px;
    }

    .data_breakdown_chip_menu {
        font-family: Poppins;
        color: white !important;
        font-weight: 600;
        width: 160px;
        justify-content: center;
        margin-left: 1px;
        margin-right: 1px;
        float: left;
    }

    .filter-title{
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 1em;
    }
    .filter-control{
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 0.9em;
    }

    .main-card-title{
        font-family: Poppins;
        font-weight: 600;
        font-size: 2em;
    }
    
    .v-chip.v-chip--outlined.v-chip.v-chip{
        color: black !important;
    }
</style>